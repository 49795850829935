<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_supplier') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
      >{{ $t('message.save') }}</el-button>
      <el-button type="primary" size="small" class="mr-1" @submit="submit(true)">{{ $t('message.save_and_create_new') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small" @click="close">{{ $t('message.close') }}</el-button>
    </header>

    <div class="body__modal">
      <el-form ref="form" :model="form" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item :label="columns.name.title">
              <el-input v-model="form.name" :placeholder="columns.name.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.full_name.title">
              <el-input v-model="form.full_name" :placeholder="columns.full_name.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.email.title">
              <el-input v-model="form.email" :placeholder="columns.email.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.phone.title">
              <el-input v-model="form.phone" :placeholder="columns.phone.title"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item :label="columns.fax.title">
              <el-input v-model="form.fax" :placeholder="columns.fax.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.actual_address.title">
              <el-input v-model="form.actual_address" :placeholder="columns.actual_address.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.description.title">
              <el-input type="textarea" v-model="form.description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="columns.type.title">
              <el-select v-model="form.type" :placeholder="columns.type.title">
                <el-option :label="$t('message.for_personal')" value="1"></el-option>
                <el-option :label="$t('message.for_bussiness')" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="columns.inn.title" v-if="form.type == 2">
              <el-input v-model="form.inn" :placeholder="columns.inn.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.mfo.title" v-if="form.type == 2">
              <el-input v-model="form.mfo" :placeholder="columns.mfo.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.okonx.title" v-if="form.type == 2">
              <el-input v-model="form.okonx" :placeholder="columns.okonx.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.oked.title" v-if="form.type == 2">
              <el-input v-model="form.oked" :placeholder="columns.oked.title"></el-input>
            </el-form-item>
            <el-form-item :label="columns.rkp_nds.title" v-if="form.type == 2">
              <el-input v-model="form.rkp_nds" :placeholder="columns.rkp_nds.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <!-- Old -->
        <el-row :gutter="40">
          <el-col :span="8">
            <el-card class="box-card mt-3" v-for="(contact, index) in form.old_contacts" :key="index">
              <div slot="header" class="clearfix">
                <span>{{ $t('message.contact') }}</span>
                <el-button
                  type="danger"
                  style="float: right; padding: 3px 0"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  @click.prevent="deleteContact(contact.id, index)"
                ></el-button>
              </div>
              <div class="text item">
                <el-form-item :label="columns.contact.full_name.title">
                  <el-input v-model="contact.full_name" :placeholder="columns.contact.full_name.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.position.title">
                  <el-input v-model="contact.position" :placeholder="columns.contact.position.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.phone.title">
                  <el-input v-model="contact.phone" :placeholder="columns.contact.phone.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.email.title">
                  <el-input v-model="contact.email" :placeholder="columns.contact.email.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.description.title">
                  <el-input v-model="contact.description" :placeholder="columns.contact.description.title"></el-input>
                </el-form-item>
              </div>
            </el-card>
          </el-col>

          <el-col :span="8">
            <el-card class="box-card mt-3" v-for="(bank_account, index) in form.old_bank_accounts" :key="index">
              <div slot="header" class="clearfix">
                <span>{{ $t('message.bank_account') }}</span>
                <el-button
                  type="danger"
                  style="float: right; padding: 3px 0"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  @click.prevent="deleteBankAccount(bank_account.id, index)"
                ></el-button>
              </div>
              <div class="text item">
                <el-form-item :label="columns.bank.bank.title">
                  <el-input v-model="bank_account.bank" :placeholder="columns.bank.bank.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.bank.address.title">
                  <el-input v-model="bank_account.address" :placeholder="columns.bank.address.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.bank.correspondent_account.title">
                  <el-input v-model="bank_account.correspondent_account" :placeholder="columns.bank.correspondent_account.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.bank.checking_account.title">
                  <el-input v-model="bank_account.checking_account" :placeholder="columns.bank.checking_account.title"></el-input>
                </el-form-item>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <!-- New -->
        <el-row :gutter="40">
          <el-col :span="8">
            <el-card class="box-card mt-3" v-for="(contact, index) in form.contacts" :key="index">
              <div slot="header" class="clearfix">
                <span>{{ $t('message.contact') }}</span>
                <el-button
                  type="danger"
                  style="float: right; padding: 3px 0"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  @click.prevent="removeContact(index)"
                ></el-button>
              </div>
              <div class="text item">
                <el-form-item :label="columns.contact.full_name.title">
                  <el-input v-model="contact.full_name" :placeholder="columns.contact.full_name.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.position.title">
                  <el-input v-model="contact.position" :placeholder="columns.contact.position.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.phone.title">
                  <el-input v-model="contact.phone" :placeholder="columns.contact.phone.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.email.title">
                  <el-input v-model="contact.email" :placeholder="columns.contact.email.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.contact.description.title">
                  <el-input v-model="contact.description" :placeholder="columns.contact.description.title"></el-input>
                </el-form-item>
              </div>
            </el-card>
            <el-button type="success" class="mt-3" icon="el-icon-plus" round @click.prevent="addContact">{{ $t('message.contact') }}</el-button>
          </el-col>

          <el-col :span="8">
            <el-card class="box-card mt-3" v-for="(bank_account, index) in form.bank_accounts" :key="index">
              <div slot="header" class="clearfix">
                <span>{{ $t('message.bank_account') }}</span>
                <el-button
                  type="danger"
                  style="float: right; padding: 3px 0"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  @click.prevent="removeBankAccount(index)"
                ></el-button>
              </div>
              <div class="text item">
                <el-form-item :label="columns.bank.bank.title">
                  <el-input v-model="bank_account.bank" :placeholder="columns.bank.bank.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.bank.address.title">
                  <el-input v-model="bank_account.address" :placeholder="columns.bank.address.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.bank.correspondent_account.title">
                  <el-input v-model="bank_account.correspondent_account" :placeholder="columns.bank.correspondent_account.title"></el-input>
                </el-form-item>
                <el-form-item :label="columns.bank.checking_account.title">
                  <el-input v-model="bank_account.checking_account" :placeholder="columns.bank.checking_account.title"></el-input>
                </el-form-item>
              </div>
            </el-card>
            <el-button type="success" class="mt-3" icon="el-icon-plus" round @click.prevent="addBankAccount">{{  $t('message.bank_account') }}</el-button>
          </el-col>
        </el-row>
    </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

export default {
  mixins: [drawerChild, form],
  computed: {
    ...mapGetters({
      columns: "suppliers/columns",
      model: "suppliers/model",
      rules: "suppliers/rules",
    }),
  },
  methods: {
    ...mapActions({
      save: "suppliers/store",
      update: "suppliers/update",
      show: "suppliers/show",
      empty: "suppliers/empty",
      destroyContact: "suppliers/destroyContact",
      destroyBankAccount: "suppliers/destroyBankAccount",
    }),
    submit(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          if (this.form.id == null) {
            this.save(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.form = res.data.supplier;
                this.$alert(res);
              })
              .catch((err) => {
                this.$alert(err);
                this.loadingButton = false;
              });
          } else {
            this.form.new_contacts = this.form.contacts;
            this.contacts = [];
            this.form.new_bank_accounts = this.form.bank_accounts;
            this.bank_accounts = [];
            this.update(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          }
        }
      });
    },
    addContact() {
      this.form.contacts.push({});
    },
    removeContact(index) {
      this.form.contacts.splice(index, 1);
    },
    addBankAccount() {
      this.form.bank_accounts.push({});
    },
    removeBankAccount(index) {
      this.form.bank_accounts.splice(index, 1);
    },
    deleteContact(id, index) {
      this.destroyContact(id)
        .then(() => {
          this.form.old_contacts.splice(index, 1);
        })
        .catch((error) => {
          this.$alert(error);
        });
    },
    deleteBankAccount(id, index) {
      this.destroyBankAccount(id)
        .then(() => {
          this.form.old_bank_accounts.splice(index, 1);
        })
        .catch((error) => {
          this.$alert(error);
        });
    }
  },
};
</script>
