<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.suppliers") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.full_name.show">{{ columns.full_name.title }}</th>
            <th v-if="columns.code.show">{{ columns.code.title }}</th>
            <th v-if="columns.phone.show">{{ columns.phone.title }}</th>
            <th v-if="columns.fax.show">{{ columns.fax.title }}</th>
            <th v-if="columns.email.show">{{ columns.email.title }}</th>
            <th v-if="columns.description.show">
              {{ columns.description.title }}
            </th>
            <th v-if="columns.actual_address.show">
              {{ columns.actual_address.title }}
            </th>
            <th v-if="columns.type.show">{{ columns.type.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.updated_at.show">
              {{ columns.updated_at.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.full_name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.full_name"
                :placeholder="columns.full_name.title"
              ></el-input>
            </th>

            <th v-if="columns.code.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.code"
                :placeholder="columns.code.title"
              ></el-input>
            </th>
            <th v-if="columns.phone.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.phone"
                :placeholder="columns.phone.title"
              ></el-input>
            </th>
            <th v-if="columns.fax.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.fax"
                :placeholder="columns.fax.title"
              ></el-input>
            </th>
            <th v-if="columns.email.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.email"
                :placeholder="columns.email.title"
              ></el-input>
            </th>
            <th v-if="columns.description.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.description"
                :placeholder="columns.description.title"
              ></el-input>
            </th>
            <th v-if="columns.actual_address.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.actual_address"
                :placeholder="columns.actual_address.title"
              ></el-input>
            </th>
            <th v-if="columns.type.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.type"
                :placeholder="columns.type.title"
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="supplier in list"
            :key="supplier.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ supplier.id }}</td>
            <td v-if="columns.name.show">{{ supplier.name }}</td>
            <td v-if="columns.full_name.show">{{ supplier.full_name }}</td>
            <td v-if="columns.code.show">{{ supplier.code }}</td>
            <td v-if="columns.phone.show">{{ supplier.phone }}</td>
            <td v-if="columns.fax.show">{{ supplier.fax }}</td>
            <td v-if="columns.email.show">{{ supplier.email }}</td>
            <td v-if="columns.description.show">{{ supplier.description }}</td>
            <td v-if="columns.actual_address.show">
              {{ supplier.actual_address }}
            </td>
            <td v-if="columns.type.show">{{ supplier.type }}</td>
            <td v-if="columns.created_at.show">{{ supplier.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ supplier.updated_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="supplier"
                name="suppliers"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
    <el-drawer
      size="85%"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";

export default {
  mixins: [list, drawer],
  components: {
    CrmCreate,
  },
  data: () => ({
    loadingData: false,
    drawer: {
      create: {
        name: "create",
        status: false,
      },
    },
    actions: ["edit", "delete"],
  }),
  computed: {
    ...mapGetters({
      list: "suppliers/list",
      columns: "suppliers/columns",
      pagination: "suppliers/pagination",
      filter: "suppliers/filter",
      sort: "suppliers/sort",
    }),
  },
  methods: {
    ...mapActions({
      getSuppliers: "suppliers/index",
      updateSort: "suppliers/updateSort",
      updateFilter: "suppliers/updateFilter",
      updateColumn: "suppliers/updateColumn",
      updatePagination: "suppliers/updatePagination",
      showSupplier: "suppliers/show",
      empty: "suppliers/empty",
      delete: "suppliers/destroy",
      refresh: "suppliers/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getSuppliers(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    refreshData() {
      this.refresh().then(() => {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
      });
    },
    async edit(model) {
      await this.showSupplier(model.id).then(() => {
        this.drawer.create.status = true;
      });
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
